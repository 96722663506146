<template>
  <b-modal id="group-code-pop" centered size="md"
           @show="checkGroupCode"
           @hide="hideModal"
           v-model="showGroupCodePopUp">
    <template #modal-header>
      <div class="popUp-header">
        <h4>Group Code</h4>
        <b-button
          variant="close-btn"
          @click="closeModal"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div>
        <div class="form-group mb-3">
          <label>Enter Group Code</label>
          <input
            type="text"
            v-model="groupCode"
            @input="removeGroupErrorMessage()"
            :class="{
              'form-control is-invalid': $v.groupCode.$error || groupErrorFlag,
            }"
            class="custom-input"
            placeholder="Enter Group Code Eg: ABCDED"
          />
          <div
            class="form-group__message text-left ml-3 mt-2"
            v-if="!$v.groupCode.required && $v.groupCode.$error"
          >
            Field is required
          </div>
          <div class="error_message mt-2" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </div>
        <div class="form-group">
          <button class="univ-btn" @click="enrollToGroup()">Continue</button>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  name: "GroupEnrollment",
  data: () => ({
    groupCode: null,
    showProceedConformation: false,
    groupErrorFlag: false,
    repErrorFlag: false,
    errorMessage: null,
  }),
  props: {
    showGroupCodePopUp: Boolean,
  },
  validations: {
    groupCode: {
      required,
    },
  },
  methods: {
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
      app.repErrorFlag = false;
      app.errorMessage = null;
    },
    enrollToGroup() {
      let app = this;
      app.$v.groupCode.$touch();
      if (app.$v.groupCode.$invalid) {
        return false;
      } else {
        axios
          .post(process.env.VUE_APP_API_BASE + "/set-enrollment-group-code",  {
              group_code: app.groupCode,
              enrollment_id: window.localStorage.getItem("tempId"),
            },
          )
          .then((res) => {
            console.log(res)
            window.localStorage.setItem('groupCode', app.groupCode)
            app.$emit('onSuccessEnroll')
            app.notifyMsg(res);
            app.closeModal();
          })
          .catch((err) => {
            app.errorMessage = err?.response?.data?.message;
            console.log(err);
          });
      }
    },
    notifyMsg (response) {
      this.$notify({
        title: response.data.message,
        icon: 'ti-alert',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'success'
      })
    },
    checkGroupCode () {
      let app = this
      app.groupCode = window.localStorage.getItem("groupCode");
      app.errorMessage = ''
    },
    closeModal() {
      this.$emit('update:showGroupCodePopUp', false);
    },
    hideModal() {
      this.closeModal();
    }
  },
};
</script>

<style lang="scss" scoped></style>
