import axios from 'axios';
export async function getGroupCodeStatus (url, tempId) {
 let groupCodeResponse = {}
 let params = {
  enrollment_id: tempId,
 }
 try {
   const response = await axios.get(url, { params })
   if (response.data.status === 'success') {
    groupCodeResponse = response.data.data;
   }
 } catch (error) {
  console.log(error)
 }
 return groupCodeResponse
}